import React from 'react'



const Courses = () => {
    return (
        <div>
            <h2 className="page-header">
                Courses
            </h2>
        </div>
    )
}

export default Courses
